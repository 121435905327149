/* eslint-disable vue/one-component-per-file */
import { createApp, defineAsyncComponent } from 'vue';
import { createI18n } from 'vue-i18n';
import { createGtm } from '@gtm-support/vue-gtm';
import type { I18n } from 'vue-i18n';
import { QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import { getSessionGlobals, getSiteGlobals } from '@/utils/domainUtils';
import type { MieleStoredGlobals  } from '@/types/shared-types';

const HeaderApp = defineAsyncComponent(() => import('./HeaderApp.vue'));
const FooterApp = defineAsyncComponent(() => import('./FooterApp.vue'));
const isProduction = process.env.NODE_ENV === 'production';

function getTrackingPlugin(siteGlobals: MieleStoredGlobals) {
    const devId = 'GTM-WW2X6M6';
    const gtmId = siteGlobals?.['module.tracking.GtmId'] || devId;
    return createGtm({
        id: isProduction ? gtmId : devId,
        debug: !isProduction,
        enabled: true,
        loadScript: false,
        defer: false,
    });
}

/**
 * Checks for a local in the site globals.
 * Otherwise try to get it from the browser and
 * return the two character code version of the locale
 */
const getCurrentLocalLanguage = (): string => {
    const sessionGlobals = getSessionGlobals();
    const langCode = sessionGlobals?.['general.language'].toLowerCase();
    const countryCode = sessionGlobals?.['general.country'].toUpperCase();
    return `${langCode}-${countryCode}`;
};

export const i18n: I18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: getCurrentLocalLanguage(), // set locale
    fallbackLocale: 'en-US', // set fallback locale
    globalInjection: false,
    messages: {},
});

const headMountEl = document.querySelector('#dhf-header') as HTMLElement;
const footMountEl = document.querySelector('#dhf-footer') as HTMLElement;

const queryClient = new QueryClient();

const headApp = createApp(HeaderApp, { 
        ...headMountEl?.dataset,
    })
    .use(i18n)
    .use(VueQueryPlugin, { queryClient });

const footApp = createApp(FooterApp, { 
        ...footMountEl?.dataset, 
    })
    .use(i18n)
    .use(VueQueryPlugin, { queryClient });

(async function () {
    const siteGlobals = await getSiteGlobals();
    if (siteGlobals) {
        const trackingPlugin = getTrackingPlugin(siteGlobals);
        headApp.use(trackingPlugin);
        footApp.use(trackingPlugin);
    }
})();

headApp.mount('#dhf-header');
footApp.mount('#dhf-footer');
