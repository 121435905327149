export const domainsByCountryCode = {
    global: 'miele.com',
    dk: 'miele.dk',
    uk: 'miele.co.uk',
    us: 'mieleusa.com',
    nl: 'miele.nl',
    be: 'miele.be',
    lu: 'miele.lu',
    fi: 'miele.fi',
    no: 'miele.no',
    se: 'miele.se',
    pl: 'miele.pl',
    ch: 'miele.ch',
    gr: 'miele.gr',
    fr: 'miele.fr',
    es: 'miele.es',
    pt: 'miele.pt',
    ie: 'miele.ie',
    za: 'miele.co.za',
    at: 'miele.at',
    de: 'miele.de',
    au: 'miele.com.au',
    nz: 'miele.co.nz',
    jp: 'miele.jp',
    ae: 'miele.ae',
    hk: 'miele.hk',
    cn: 'miele.cn',
    hr: 'miele.hr',
    sl: 'miele.sl',
    cz: 'miele.cz',
    sk: 'miele.sk',
    my: 'miele.my',
    sg: 'miele.sg',
    kr: 'miele.co.kr',
    lt: 'miele.lt',
    lv: 'miele.lv',
    ee: 'miele.ee',
    it: 'miele.it',
    ca: 'miele.ca',
    bg: 'miele.bg',
    hu: 'miele.hu',
    mx: 'miele.com.mx',
    cl: 'miele.cl',
    ro: 'miele.ro',
    ru: 'miele.ru',
    ua: 'miele.ua',
    kz: 'miele.kz',
    rs: 'miele.rs',
    tr: 'miele.tr',
    in: 'miele.in',
    th: 'miele.co.th',
    br: 'mielebrasil.com.br',
} as const;

export const countryCodeLanguageMap = [
    { countryCode: 'be', languages: ['nl', 'fr'] },
    { countryCode: 'ch', languages: ['de', 'fr', 'it'] },
    { countryCode: 'ca', languages: ['en', 'fr'] },
    { countryCode: 'lu', languages: ['fr', 'de'] },
];

export type SUPPORTED_COUNTRIES_ISO_ALPHA2 = keyof typeof domainsByCountryCode;
